<template>
  <nav v-if="marqueeData" class="align-middle">
      <div class="container">
          <vue3-marquee class=" news-content " behavior="scroll" direction="left">
            <p>Active Exchanges: <b>{{marqueeData.active_exchanges}}</b></p> 
            <p>Total Exchanges: <b>{{marqueeData.total_exchanges}}</b></p>  
            <p>Active Crypto Assets: <b>{{marqueeData.active_cryptocurrencies}}</b></p>  
            <p>Total Crypto Assets: <b>{{marqueeData.total_cryptocurrencies}}</b></p>     
            <p><font-awesome-icon class="icons" :icon="['fab', 'btc']" /> Bitcoin Dominance: <b>{{marqueeData.btc_dominance.toFixed(2)}}%</b></p>    
            <p><font-awesome-icon class="icons" :icon="['fab', 'ethereum']" /> Ethereum Dominance: <b>{{marqueeData.eth_dominance.toFixed(2)}}%</b></p>
          </vue3-marquee>
      </div>
  </nav>
</template>


<script>
// @ is an alias to /src
import EventService from '@/services/EventService.js';
import Vue3Marquee from "vue3-marquee";

export default {
  name: "navbar",
  components: {
    Vue3Marquee
  },
  data() {
    return {
      marqueeData: null
    }
  },
  created() {
    EventService.getData()
    .then(response => {
      this.marqueeData = response.data 
    })
    .catch(error => {console.log(error)})
  }
};

</script>



<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Source+Serif+4:ital@1&display=swap');

nav {
      position: fixed;
      top: 0;
      width: 100%;
    }

p {
  color: #fff;
}

a {
    color: #fff;
    text-decoration: none
}

.ticker {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 2px;
}

.news {
    width: 100%;
    padding: 0 1%;
    color: #fff;
    text-align: center;
    font-size: 24px;
}

.news marquee {
    font-size: 18px;
}

.news-content {
  height: 10px;
}

.news-content p {
    margin-right: 35px;
    display: inline;
    align-items:center;
    font-size: 24px;
}
.icons {
    height: 20px;
    width: 20px;
    margin: 2px;
  }


@media only screen and (max-width: 670px) {
  .news {
    margin: 1.25% 0;
    font-size: 16px;
  }
  .news marquee {
    font-size: 16px;
  }
  .news-content {
    height: 20px;
  }
  .news-content p {
    margin-right: 15px;
    font-size: 16px;
  }
  .icons {
    height: 15px;
    width: 15px;
    margin: 1px;
  }
}

</style>

