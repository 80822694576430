<template>
    <navbar></navbar>
    <div id="clock">
      <h1>B3PO</h1>
        <p class="date">Something New Is Coming</p><br>
        <p class="date">1-1-25 Fri</p>
        <p class="date">{{ diff.month }} Months & {{ diff.day }} Days</p>
        <p class="time"> 
            {{ diff.hour }}:{{ diff.minute }}:{{ diff.second }}
        </p>
        <p class="text">DIGITAL CLOCK with Vue.js</p>
        <p class="text" >View current site to <a href="https://b3po.io/contact" style="text-decoration: none; color: white;">Contact</a></p>
    </div>

</template>

<script>

import navbar from "@/components/navbar.vue";

const futureDate = new Date(2025, 1, 1);
const getDateDiff = (date1, date2) => {
  const diff = new Date(date2.getTime() - date1.getTime());
  return {
    year: diff.getUTCFullYear() - 1970,
    month: diff.getUTCMonth(),
    day: diff.getUTCDate() - 1,
    hour: diff.getUTCHours(),
    minute: diff.getUTCMinutes(),
    second: diff.getUTCSeconds(),
  };
};

export default {
  name: "clock",
  components: {
    navbar
  },

  data() {
    return {
      futureDate,
      diff: {},
      timer: undefined,
    };
  },
  methods: {
    getDiff() {
      this.diff = getDateDiff(new Date(), futureDate);
    },
    formatDate(date) {
      let d = new Date(date),
        month = (d.getMonth() + 1).toString(),
        day = d.getDate().toString(),
        year = d.getFullYear().toString();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },
  },
  beforeMount() {
    this.timer = setInterval(this.getDiff, 1000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
};

</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css?family=Share+Tech+Mono');


    p {
      margin: 0;
      padding: 0;
    }
    #clock {
      font-family: "Share Tech Mono", monospace;
      color: #ffffff;
      text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: #daf6ff;
      text-shadow: 0 0 20px #0aafe6, 0 0 20px rgba(10, 175, 230, 0);
    }
    #clock .time {
      letter-spacing: 0.05em;
      font-size: 80px;
      padding: 5px 0;
    }
    #clock .date {
      letter-spacing: 0.1em;
      font-size: 24px;
    }
    #clock .text {
      letter-spacing: 0.1em;
      font-size: 12px;
      padding: 20px 0 0;
    }

    @media only screen and (max-width: 600px) {

        h1 {
          font-size: 20px;
        }
        #clock {
          max-width: 500px;
        }
        #clock .time {
          font-size: 40px;
          padding: 0;
        }
        #clock .date {
          font-size: 16px;
        }
        #clock .text {
          font-size: 12px;
        }
}
</style>
