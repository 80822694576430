import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import bootstrapVue from "bootstrap-vue-3"
import Vue3Marquee from 'vue3-marquee'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faVuejs, faBitcoin, faBtc, faEthereum, } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

    
library.add(faVuejs, faBitcoin, faBtc, faEthereum)


createApp(App)
    .use(store)
    .use(router)
    .use(bootstrapVue)
    .component("font-awesome-icon", FontAwesomeIcon)
    .use(Vue3Marquee)
    .mount("#app");
