import axios from 'axios'


const apiClient = axios.create({
    baseURL: 'https://api.b3po.io', 
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type' : 'application/json'
    }
})

export default {
    getData() {
        return apiClient.get('/marquee')
    }
}