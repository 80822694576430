<template>
  <router-view />
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Source+Serif+4:ital@1&display=swap');

body {
      background: rgb(10,46,56);
      background: radial-gradient(circle, rgba(10,46,56,1) 0%, rgba(0,0,0,1) 70%);
      background-size: 100%;
    }

b {
  color: #4facfe;
}

</style>
